const red = {
  '0': {
    hex: '#fefcfc',
    css: 'rgb(254,252,252)',
    hsl: 'hsl(0,50%,99.22%)',
    rgb: {
      r: 254,
      g: 252,
      b: 252,
    },
  },
  '10': {
    hex: '#f8dddd',
    css: 'rgb(248,221,221)',
    hsl: 'hsl(0,65.85%,91.96%)',
    rgb: {
      r: 248,
      g: 221,
      b: 221,
    },
  },
  '20': {
    hex: '#f2bdbd',
    css: 'rgb(242,189,189)',
    hsl: 'hsl(0,67.09%,84.51%)',
    rgb: {
      r: 242,
      g: 189,
      b: 189,
    },
  },
  '30': {
    hex: '#ee9c9c',
    css: 'rgb(238,156,156)',
    hsl: 'hsl(0,70.69%,77.25%)',
    rgb: {
      r: 238,
      g: 156,
      b: 156,
    },
  },
  '40': {
    hex: '#ea7979',
    css: 'rgb(234,121,121)',
    hsl: 'hsl(0,72.9%,69.61%)',
    rgb: {
      r: 234,
      g: 121,
      b: 121,
    },
  },
  '50': {
    hex: '#e85151',
    css: 'rgb(232,81,81)',
    hsl: 'hsl(0,76.65%,61.37%)',
    rgb: {
      r: 232,
      g: 81,
      b: 81,
    },
  },
  '60': {
    hex: '#c23a63',
    css: 'rgb(194,58,99)',
    hsl: 'hsl(341.91,53.97%,49.41%)',
    rgb: {
      r: 194,
      g: 58,
      b: 99,
    },
  },
  '70': {
    hex: '#992f63',
    css: 'rgb(153,47,99)',
    hsl: 'hsl(330.57,53%,39.22%)',
    rgb: {
      r: 153,
      g: 47,
      b: 99,
    },
  },
  '80': {
    hex: '#742457',
    css: 'rgb(116,36,87)',
    hsl: 'hsl(321.75,52.63%,29.8%)',
    rgb: {
      r: 116,
      g: 36,
      b: 87,
    },
  },
  '90': {
    hex: '#521947',
    css: 'rgb(82,25,71)',
    hsl: 'hsl(311.58,53.27%,20.98%)',
    rgb: {
      r: 82,
      g: 25,
      b: 71,
    },
  },
  '100': {
    hex: '#330e33',
    css: 'rgb(51,14,51)',
    hsl: 'hsl(300,56.92%,12.75%)',
    rgb: {
      r: 51,
      g: 14,
      b: 51,
    },
  },
  // stops: 11,
  default: {
    hex: '#e85151',
    css: 'rgb(232,81,81)',
    hsl: 'hsl(0,76.65%,61.37%)',
    rgb: {
      r: 232,
      g: 81,
      b: 81,
    },
  },
  // defaultStop: 50,
};

export default red;
