// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../../node_modules/bs-platform/lib/es6/caml_option.js";
import * as GoIcon$KifuIo from "../../common/GoIcon.bs.js";
import * as SpeedDial$KifuIo from "../../common/SpeedDial.bs.js";
import * as EditingContext$KifuIo from "../../goban/editing/EditingContext.bs.js";
import * as SetToolOptions$KifuIo from "./SetToolOptions.bs.js";

function EditingToolsSpeedDial(Props) {
  var direction = Props.direction;
  var flowDirection = Props.flowDirection;
  var match = EditingContext$KifuIo.useEditingContext(/* () */0);
  var match$1 = match[0].tool;
  var tmp = {
    direction: direction,
    children: null,
    icon: match$1 ? (
        match$1[0] >= -588596599 ? React.createElement(GoIcon$KifuIo.WhiteStone.make, {
                radius: 13
              }) : React.createElement(GoIcon$KifuIo.BlackStone.make, {
                radius: 13
              })
      ) : React.createElement(GoIcon$KifuIo.DoubleStone.make, {
            radius: 13
          })
  };
  if (flowDirection !== undefined) {
    tmp.flowDirection = Caml_option.valFromOption(flowDirection);
  }
  return React.createElement(SpeedDial$KifuIo.make, tmp, React.createElement(SetToolOptions$KifuIo.Play.make, { }), React.createElement(SetToolOptions$KifuIo.BlackStone.make, { }), React.createElement(SetToolOptions$KifuIo.WhiteStone.make, { }));
}

var make = EditingToolsSpeedDial;

export {
  make ,
  
}
/* react Not a pure module */
