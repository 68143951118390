// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as GoGameContextTsx from "../../goban/GoGameContext.tsx";

function useGoGameContext(prim) {
  return GoGameContextTsx.useGoGameContext();
}

export {
  useGoGameContext ,
  
}
/* ../../goban/GoGameContext.tsx Not a pure module */
