// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "../../../../node_modules/bs-platform/lib/es6/caml_option.js";
import * as GoIcon$KifuIo from "../../common/GoIcon.bs.js";
import * as EditingContext$KifuIo from "../../goban/editing/EditingContext.bs.js";
import * as SpeedDialOption$KifuIo from "../../common/SpeedDialOption.bs.js";

function SetToolOptions$BlackStone(Props) {
  var style = Props.style;
  var labelDirection = Props.labelDirection;
  var match = EditingContext$KifuIo.useEditingContext(/* () */0);
  var dispatch = match[1];
  var handleClick = function (param) {
    Curry._1(dispatch, /* SetTool */[/* AddStone */[/* Black */-937474657]]);
    return /* () */0;
  };
  var match$1 = match[0].tool;
  var highlighted = match$1 ? match$1[0] === -937474657 : false;
  var tmp = {
    label: "Add Black Stone",
    children: React.createElement(GoIcon$KifuIo.BlackStone.make, {
          radius: 10
        }),
    onClick: handleClick,
    highlighted: highlighted
  };
  if (labelDirection !== undefined) {
    tmp.labelDirection = Caml_option.valFromOption(labelDirection);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(SpeedDialOption$KifuIo.make, tmp);
}

var BlackStone = {
  make: SetToolOptions$BlackStone
};

function SetToolOptions$WhiteStone(Props) {
  var style = Props.style;
  var labelDirection = Props.labelDirection;
  var match = EditingContext$KifuIo.useEditingContext(/* () */0);
  var dispatch = match[1];
  var handleClick = function (param) {
    Curry._1(dispatch, /* SetTool */[/* AddStone */[/* White */-588596599]]);
    return /* () */0;
  };
  var match$1 = match[0].tool;
  var highlighted = match$1 ? match$1[0] === -588596599 : false;
  var tmp = {
    label: "Add White Stone",
    children: React.createElement(GoIcon$KifuIo.WhiteStone.make, {
          radius: 10
        }),
    onClick: handleClick,
    highlighted: highlighted
  };
  if (labelDirection !== undefined) {
    tmp.labelDirection = Caml_option.valFromOption(labelDirection);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(SpeedDialOption$KifuIo.make, tmp);
}

var WhiteStone = {
  make: SetToolOptions$WhiteStone
};

function SetToolOptions$Play(Props) {
  var style = Props.style;
  var labelDirection = Props.labelDirection;
  var match = EditingContext$KifuIo.useEditingContext(/* () */0);
  var dispatch = match[1];
  var handleClick = function (param) {
    Curry._1(dispatch, /* SetTool */[/* AddMove */0]);
    return /* () */0;
  };
  var match$1 = match[0].tool;
  var highlighted = match$1 ? false : true;
  var tmp = {
    label: "Play",
    children: React.createElement(GoIcon$KifuIo.DoubleStone.make, {
          radius: 10
        }),
    onClick: handleClick,
    highlighted: highlighted
  };
  if (labelDirection !== undefined) {
    tmp.labelDirection = Caml_option.valFromOption(labelDirection);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(SpeedDialOption$KifuIo.make, tmp);
}

var Play = {
  make: SetToolOptions$Play
};

export {
  BlackStone ,
  WhiteStone ,
  Play ,
  
}
/* react Not a pure module */
