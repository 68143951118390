// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Js_mapperRt from "../../../node_modules/bs-platform/lib/es6/js_mapperRt.js";

var jsMapperConstantArray = [
  /* tuple */[
    -937474657,
    "b"
  ],
  /* tuple */[
    -588596599,
    "w"
  ]
];

function stoneColorToJs(param) {
  return Js_mapperRt.binarySearch(2, param, jsMapperConstantArray);
}

function stoneColorFromJs(param) {
  return Js_mapperRt.revSearch(2, jsMapperConstantArray, param);
}

export {
  stoneColorToJs ,
  stoneColorFromJs ,
  
}
/* No side effect */
