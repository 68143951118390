/* TypeScript file generated from MarkupLogic.re by genType. */
/* eslint-disable import/first */


// tslint:disable-next-line:no-var-requires
const MarkupLogicBS = require('./MarkupLogic.bs');

import {label as GameState_label} from './GameState.gen';

export const useVariations: () => GameState_label[] = MarkupLogicBS.useVariations;
