import React from 'react';
import 'styled-components/macro';
import { css } from 'styled-components';
import { highlight } from 'style';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

const Home = () => (
  <div
    css={css`
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        color: ${highlight};
        margin: 1rem;
      }

      a {
        margin: 1rem;
        text-decoration: none;
      }
    `}
  >
    <h1
      css={`
        margin-bottom: 0.5rem;
      `}
    >
      Kifu.io
    </h1>
    <p>Mobile friendly SGF hosting</p>
    <Link to="/upload">
      <Button>Upload a game</Button>
    </Link>
    <p>or</p>
    <Link to="/create">
      <Button>Create a new SGF</Button>
    </Link>
  </div>
);

export default Home;
