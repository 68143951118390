// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/bs-platform/lib/es6/curry.js";
import * as React from "react";

function useMousePosition(coordToPointIndex, onClick) {
  var match = React.useState((function () {
          return ;
        }));
  var setMousePosition = match[1];
  var mousePosition = match[0];
  var getPos = function ($$event) {
    var nativeEvent = $$event.nativeEvent;
    return {
            x: Curry._1(coordToPointIndex, nativeEvent.offsetX),
            y: Curry._1(coordToPointIndex, nativeEvent.offsetY)
          };
  };
  var handleMouseMove = function ($$event) {
    var newPos = getPos($$event);
    var positionChanged = function (param) {
      if (param.x !== newPos.x) {
        return true;
      } else {
        return param.y !== newPos.y;
      }
    };
    if (mousePosition !== undefined) {
      if (positionChanged(mousePosition)) {
        return Curry._1(setMousePosition, (function (param) {
                      return newPos;
                    }));
      } else {
        return /* () */0;
      }
    } else {
      return Curry._1(setMousePosition, (function (param) {
                    return newPos;
                  }));
    }
  };
  var handleMouseLeave = function (param) {
    return Curry._1(setMousePosition, (function (param) {
                  return ;
                }));
  };
  var handleClick = function ($$event) {
    return Curry._1(onClick, getPos($$event));
  };
  return /* tuple */[
          mousePosition,
          {
            onMouseMove: handleMouseMove,
            onMouseLeave: handleMouseLeave,
            onClick: handleClick
          }
        ];
}

export {
  useMousePosition ,
  
}
/* react Not a pure module */
