/* TypeScript file generated from EditingToolsSpeedDial.re by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// tslint:disable-next-line:no-var-requires
const EditingToolsSpeedDialBS = require('./EditingToolsSpeedDial.bs');

import {direction as SpeedDial_direction} from '../../../../src/reason/common/SpeedDial.gen';

// tslint:disable-next-line:interface-over-type-literal
export type Props = { readonly direction: SpeedDial_direction; readonly flowDirection?: string };

export const make: React.ComponentType<{ readonly direction: SpeedDial_direction; readonly flowDirection?: string }> = EditingToolsSpeedDialBS.make;
