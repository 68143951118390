// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "../../../node_modules/bs-platform/lib/es6/js_dict.js";
import * as Belt_Array from "../../../node_modules/bs-platform/lib/es6/belt_Array.js";
import * as GameContext$KifuIo from "./GameContext.bs.js";

function getNodeMovePoint(props) {
  var b = Js_dict.get(props, "B");
  var w = Js_dict.get(props, "W");
  if (b !== undefined) {
    return Belt_Array.get(b, 0);
  } else if (w !== undefined) {
    return Belt_Array.get(w, 0);
  } else {
    return ;
  }
}

function toLabel(index, point) {
  var a = "A".charCodeAt(0) | 0;
  var label = String.fromCharCode(index + a | 0);
  return {
          point: point,
          label: label
        };
}

function useVariations(param) {
  var match = GameContext$KifuIo.useGoGameContext(/* () */0);
  var getNode = match.getNode;
  var match$1 = match.gameState;
  var sgfLabels = match$1.moveState.labels;
  var variationDisplay = match$1.properties.variationDisplay;
  var currentNode = Curry._1(getNode, match$1.node);
  return React.useMemo((function () {
                var isNotLabelled = function (point) {
                  return !Belt_Array.some(sgfLabels, (function (label) {
                                return label.point === point;
                              }));
                };
                var variationsOfChildren = function (node) {
                  var match = node.children;
                  if (match !== undefined) {
                    return Belt_Array.mapWithIndex(Belt_Array.keep(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Array.map(match, Curry.__1(getNode)), (function (child) {
                                              return child.properties;
                                            })), getNodeMovePoint), isNotLabelled), toLabel);
                  } else {
                    return [];
                  }
                };
                var variationLabels;
                if (variationDisplay !== undefined) {
                  var match = variationDisplay;
                  if (match.show) {
                    switch (match.showFor) {
                      case "CURRENT_MOVE" :
                          var match$1 = currentNode.parent;
                          variationLabels = match$1 !== undefined ? variationsOfChildren(Curry._1(getNode, match$1)) : [];
                          break;
                      case "NEXT_MOVE" :
                          variationLabels = variationsOfChildren(currentNode);
                          break;
                      default:
                        variationLabels = [];
                    }
                  } else {
                    variationLabels = [];
                  }
                } else {
                  variationLabels = [];
                }
                var match$2 = variationLabels.length;
                if (match$2 === 0 || match$2 === 1) {
                  return sgfLabels;
                } else {
                  return Belt_Array.concat(variationLabels, sgfLabels);
                }
              }), /* tuple */[
              currentNode,
              sgfLabels,
              variationDisplay,
              getNode
            ]);
}

export {
  getNodeMovePoint ,
  toLabel ,
  useVariations ,
  
}
/* react Not a pure module */
