const teal = {
  '0': {
    hex: '#dbf4ff',
    css: 'rgb(219,244,255)',
    hsl: 'hsl(198.33,100%,92.94%)',
    rgb: {
      r: 219,
      g: 244,
      b: 255,
    },
  },
  '10': {
    hex: '#b8ebff',
    css: 'rgb(184,235,255)',
    hsl: 'hsl(196.9,100%,86.08%)',
    rgb: {
      r: 184,
      g: 235,
      b: 255,
    },
  },
  '20': {
    hex: '#52daff',
    css: 'rgb(82,218,255)',
    hsl: 'hsl(192.83,100%,66.08%)',
    rgb: {
      r: 82,
      g: 218,
      b: 255,
    },
  },
  '30': {
    hex: '#00c2e8',
    css: 'rgb(0,194,232)',
    hsl: 'hsl(189.83,100%,45.49%)',
    rgb: {
      r: 0,
      g: 194,
      b: 232,
    },
  },
  '40': {
    hex: '#00a9c9',
    css: 'rgb(0,169,201)',
    hsl: 'hsl(189.55,100%,39.41%)',
    rgb: {
      r: 0,
      g: 169,
      b: 201,
    },
  },
  '50': {
    hex: '#0090ac',
    css: 'rgb(0,144,172)',
    hsl: 'hsl(189.77,100%,33.73%)',
    rgb: {
      r: 0,
      g: 144,
      b: 172,
    },
  },
  '60': {
    hex: '#007890',
    css: 'rgb(0,120,144)',
    hsl: 'hsl(190,100%,28.24%)',
    rgb: {
      r: 0,
      g: 120,
      b: 144,
    },
  },
  '70': {
    hex: '#006074',
    css: 'rgb(0,96,116)',
    hsl: 'hsl(190.34,100%,22.75%)',
    rgb: {
      r: 0,
      g: 96,
      b: 116,
    },
  },
  '80': {
    hex: '#004a5a',
    css: 'rgb(0,74,90)',
    hsl: 'hsl(190.67,100%,17.65%)',
    rgb: {
      r: 0,
      g: 74,
      b: 90,
    },
  },
  '90': {
    hex: '#003541',
    css: 'rgb(0,53,65)',
    hsl: 'hsl(191.08,100%,12.75%)',
    rgb: {
      r: 0,
      g: 53,
      b: 65,
    },
  },
  '100': {
    hex: '#002129',
    css: 'rgb(0,33,41)',
    hsl: 'hsl(191.71,100%,8.04%)',
    rgb: {
      r: 0,
      g: 33,
      b: 41,
    },
  },
  // "stops": 11,
  default: {
    hex: '#dbf4ff',
    css: 'rgb(219,244,255)',
    hsl: 'hsl(198.33,100%,92.94%)',
    rgb: {
      r: 219,
      g: 244,
      b: 255,
    },
  },
  // "defaultStop": 0
};

export default teal;
