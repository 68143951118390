// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Caml_array from "../../../node_modules/bs-platform/lib/es6/caml_array.js";
import * as Caml_format from "../../../node_modules/bs-platform/lib/es6/caml_format.js";

var monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function getMonthString(month) {
  var num = Caml_format.caml_int_of_string(month);
  if (num <= 12 && num >= 1) {
    return Caml_array.caml_array_get(monthNames, num - 1 | 0);
  } else {
    return "Invalid Month";
  }
}

function getDayString(day) {
  var suffix;
  if (day >= 4) {
    switch (day) {
      case 22 :
          suffix = "nd";
          break;
      case 23 :
          suffix = "rd";
          break;
      case 24 :
      case 25 :
      case 26 :
      case 27 :
      case 28 :
      case 29 :
      case 30 :
          suffix = "th";
          break;
      case 21 :
      case 31 :
          suffix = "st";
          break;
      default:
        suffix = "th";
    }
  } else if (day > 0) {
    switch (day - 1 | 0) {
      case 0 :
          suffix = "st";
          break;
      case 1 :
          suffix = "nd";
          break;
      case 2 :
          suffix = "rd";
          break;
      
    }
  } else {
    suffix = "th";
  }
  return String(day) + suffix;
}

export {
  monthNames ,
  getMonthString ,
  getDayString ,
  
}
/* No side effect */
