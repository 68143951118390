// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as EditingState$KifuIo from "./EditingState.bs.js";
import * as EditingContext$KifuIo from "./EditingContext.bs.js";

function EditingProvider(Props) {
  var children = Props.children;
  var match = React.useReducer(EditingState$KifuIo.reducer, {
        tool: /* AddMove */0
      });
  var dispatch = match[1];
  var state = match[0];
  var value = React.useMemo((function () {
          return /* tuple */[
                  state,
                  dispatch
                ];
        }), /* tuple */[
        state,
        dispatch
      ]);
  return React.createElement(EditingContext$KifuIo.make, EditingContext$KifuIo.makeProps(value, children, /* () */0));
}

var make = EditingProvider;

export {
  make ,
  
}
/* react Not a pure module */
