// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Js_mapperRt from "../../../node_modules/bs-platform/lib/es6/js_mapperRt.js";
import * as SpeedDialOptionTsx from "../../components/SpeedDialOption.tsx";

var jsMapperConstantArray = [
  /* tuple */[
    -57574468,
    "RIGHT"
  ],
  /* tuple */[
    847852583,
    "LEFT"
  ]
];

function labelDirectionToJs(param) {
  return Js_mapperRt.binarySearch(2, param, jsMapperConstantArray);
}

function labelDirectionFromJs(param) {
  return Js_mapperRt.revSearch(2, jsMapperConstantArray, param);
}

var make = SpeedDialOptionTsx.default;

export {
  labelDirectionToJs ,
  labelDirectionFromJs ,
  make ,
  
}
/* make Not a pure module */
