// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function getCurrentStone(playerToPlay, tool, blackStone, whiteStone) {
  if (playerToPlay !== undefined) {
    if (playerToPlay >= -588596599) {
      if (!tool) {
        return whiteStone;
      }
      
    } else if (!tool) {
      return blackStone;
    }
    
  } else if (!tool) {
    return blackStone;
  }
  if (tool[0] >= -588596599) {
    return whiteStone;
  } else {
    return blackStone;
  }
}

function clampAxis(pointVal, size) {
  if (pointVal < 0) {
    return 0;
  } else if (pointVal >= size) {
    return size - 1 | 0;
  } else {
    return pointVal;
  }
}

function clampPoint(param, param$1) {
  return /* tuple */[
          clampAxis(param[0], param$1[0]),
          clampAxis(param[1], param$1[1])
        ];
}

export {
  getCurrentStone ,
  clampAxis ,
  clampPoint ,
  
}
/* No side effect */
