// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";

var defaultContext_000 = {
  tool: /* AddMove */0
};

function defaultContext_001(action) {
  return /* () */0;
}

var defaultContext = /* tuple */[
  defaultContext_000,
  defaultContext_001
];

var context = React.createContext(defaultContext);

function useEditingContext(param) {
  return React.useContext(context);
}

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = context.Provider;

export {
  defaultContext ,
  context ,
  useEditingContext ,
  makeProps ,
  make ,
  
}
/* context Not a pure module */
