import React from 'react';
import 'styled-components/macro';
import Button from 'components/Button';
import { css } from 'styled-components';
import { useHistory } from 'react-router-dom';

const ChooseBoardSize: React.FC = () => {
  const history = useHistory();
  const chooseSize = (sizeStr: string) => () =>
    history.push(`/create?boardSize=${sizeStr}`);

  return (
    <div
      css={css`
        flex: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        > * + * {
          margin-top: 1rem;
        }
      `}
    >
      <h1>Choose board size</h1>
      <Button onClick={chooseSize('19,19')}>19 x 19</Button>
      <Button onClick={chooseSize('13,13')}>13 x 13</Button>
      <Button onClick={chooseSize('9,9')}>9 x 9</Button>
      {/* TODO: Support custom */}
      <Button
        // onClick={chooseSize('custom')}
        disabled
      >
        Custom (coming soon)
      </Button>
    </div>
  );
};

export default ChooseBoardSize;
