// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as GoIconTsx from "../../components/GoIcon.tsx";
import * as BlackStoneIconTsx from "../../components/BlackStoneIcon.tsx";
import * as WhiteStoneIconTsx from "../../components/WhiteStoneIcon.tsx";
import * as DoubleStoneIconTsx from "../../components/DoubleStoneIcon.tsx";

var make = GoIconTsx.default;

var make$1 = BlackStoneIconTsx.default;

var BlackStone = {
  make: make$1
};

var make$2 = WhiteStoneIconTsx.default;

var WhiteStone = {
  make: make$2
};

var make$3 = DoubleStoneIconTsx.default;

var DoubleStone = {
  make: make$3
};

export {
  make ,
  BlackStone ,
  WhiteStone ,
  DoubleStone ,
  
}
/* make Not a pure module */
