/* TypeScript file generated from GameState.re by genType. */
/* eslint-disable import/first */


// tslint:disable-next-line:no-var-requires
const Curry = require('bs-platform/lib/es6/curry.js');

// tslint:disable-next-line:no-var-requires
const GameStateBS = require('./GameState.bs');

import {Dict_t as Js_Dict_t} from '../../../src/reason/shims/Js.shim';

// tslint:disable-next-line:interface-over-type-literal
export type captureCounts = { readonly b: number; readonly w: number };

// tslint:disable-next-line:interface-over-type-literal
export type boardState = Js_Dict_t<string>;

// tslint:disable-next-line:interface-over-type-literal
export type playedOnDates = Js_Dict_t<Js_Dict_t<number[]>>;

// tslint:disable-next-line:interface-over-type-literal
export type application = { readonly name: string; readonly version: string };

// tslint:disable-next-line:interface-over-type-literal
export type variationDisplay = { readonly show: boolean; readonly showFor: string };

// tslint:disable-next-line:interface-over-type-literal
export type gameStateProperties = {
  readonly annotatorName?: string; 
  readonly application?: application; 
  readonly boardSize?: [number, number]; 
  readonly copyright?: string; 
  readonly eventName?: string; 
  readonly gameComment?: string; 
  readonly gameName?: string; 
  readonly opening?: string; 
  readonly overtime?: string; 
  readonly placePlayed?: string; 
  readonly playedOn?: playedOnDates; 
  readonly playerBlack?: string; 
  readonly playerWhite?: string; 
  readonly rankBlack?: string; 
  readonly rankWhite?: string; 
  readonly result?: string; 
  readonly round?: string; 
  readonly ruleSet?: string; 
  readonly source?: string; 
  readonly teamBlack?: string; 
  readonly teamWhite?: string; 
  readonly timeLimit?: number; 
  readonly userEnteringGameRecord?: string; 
  readonly variationDisplay?: variationDisplay
};

// tslint:disable-next-line:interface-over-type-literal
export type positionStatus = { readonly favoredPlayer: string; readonly magnitude: number };

// tslint:disable-next-line:interface-over-type-literal
export type moveQuality = { readonly quality: string; readonly magnitude?: number };

// tslint:disable-next-line:interface-over-type-literal
export type label = { readonly point: string; readonly label: string };

// tslint:disable-next-line:interface-over-type-literal
export type moveState = {
  readonly circles: string[]; 
  readonly comment?: string; 
  readonly estimatedScore?: number; 
  readonly hotspot?: boolean; 
  readonly lines: Array<[string, string]>; 
  readonly moveQuality?: moveQuality; 
  readonly name?: string; 
  readonly playerToPlay?: string; 
  readonly positionStatus?: positionStatus; 
  readonly squares: string[]; 
  readonly triangles: string[]; 
  readonly xMarks: string[]; 
  readonly labels: label[]
};

// tslint:disable-next-line:interface-over-type-literal
export type nodeProperties = Js_Dict_t<string[]>;

// tslint:disable-next-line:interface-over-type-literal
export type gameTreeNode = {
  readonly id: string; 
  readonly parent?: string; 
  readonly children?: string[]; 
  readonly properties?: nodeProperties; 
  readonly moveNumber?: number
};

// tslint:disable-next-line:interface-over-type-literal
export type gameTree = { readonly rootNode: string; readonly nodes: Js_Dict_t<gameTreeNode> };

// tslint:disable-next-line:interface-over-type-literal
export type gameState = {
  readonly properties: gameStateProperties; 
  readonly boardState: boardState; 
  readonly node: string; 
  readonly moveState: moveState; 
  readonly captureCounts: captureCounts
};

export const defaultCaptureCounts: captureCounts = GameStateBS.defaultCaptureCounts;

export const updateCaptureCount: (state:captureCounts, points:string[], color:string) => captureCounts = function (Arg1: any, Arg2: any, Arg3: any) {
  const result = Curry._3(GameStateBS.updateCaptureCount, Arg1, Arg2, Arg3);
  return result
};

export const addToNullableArray: (point:string, prop:(null | undefined | string[])) => string[] = function (Arg1: any, Arg2: any) {
  const result = Curry._2(GameStateBS.addToNullableArray, Arg1, (Arg2 == null ? undefined : Arg2));
  return result
};
