/* TypeScript file generated from EditingLogic.re by genType. */
/* eslint-disable import/first */


const $$toRE383455976: { [key: string]: any } = {"AddMove": 0};

// tslint:disable-next-line:no-var-requires
const CreateBucklescriptBlock = require('bs-platform/lib/es6/block.js');

// tslint:disable-next-line:no-var-requires
const Curry = require('bs-platform/lib/es6/curry.js');

// tslint:disable-next-line:no-var-requires
const EditingLogicBS = require('./EditingLogic.bs');

import {stoneColor as GobanVariants_stoneColor} from '../../../../src/reason/goban/GobanVariants.gen';

import {tool as EditingState_tool} from '../../../../src/reason/goban/editing/EditingState.gen';

export const getCurrentStone: <T1>(playerToPlay:(null | undefined | GobanVariants_stoneColor), tool:EditingState_tool, blackStone:T1, whiteStone:T1) => T1 = function <T1>(Arg1: any, Arg2: any, Arg3: any, Arg4: any) {
  const result = Curry._4(EditingLogicBS.getCurrentStone, (Arg1 == null ? undefined : Arg1), typeof(Arg2) === 'object'
    ? CreateBucklescriptBlock.__(0, [Arg2.value])
    : $$toRE383455976[Arg2], Arg3, Arg4);
  return result
};

export const clampPoint: (param_0:[number, number], param_1:[number, number]) => [number, number] = function (Arg1: any, Arg2: any) {
  const result = Curry._2(EditingLogicBS.clampPoint, Arg1, Arg2);
  return result
};
