// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "../../../node_modules/bs-platform/lib/es6/array.js";

function updateCaptureCount(state, points, color) {
  var numCaptures = points.length;
  switch (color) {
    case "b" :
        return {
                b: state.b,
                w: numCaptures + state.w | 0
              };
    case "w" :
        return {
                b: numCaptures + state.b | 0,
                w: state.w
              };
    default:
      return state;
  }
}

function addToNullableArray(point, prop) {
  if (prop !== undefined) {
    return $$Array.concat(/* :: */[
                prop,
                /* :: */[
                  [point],
                  /* [] */0
                ]
              ]);
  } else {
    return [point];
  }
}

var defaultCaptureCounts = {
  b: 0,
  w: 0
};

export {
  defaultCaptureCounts ,
  updateCaptureCount ,
  addToNullableArray ,
  
}
/* No side effect */
