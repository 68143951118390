import React, { useMemo } from 'react';
import GameView from 'goban/GameView';
import { useLocation } from 'react-router-dom';
import ChooseBoardSize from './ChooseBoardSize';

const CreatePage: React.FC = () => {
  const location = useLocation();

  const boardSize = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('boardSize');
  }, [location.search]);

  const sgf = useMemo(() => {
    const size = boardSize?.replace(',', ':') ?? '19:19';
    return `(;GM[1]SZ[${size}])`;
  }, [boardSize]);

  if (!boardSize) return <ChooseBoardSize />;
  if (boardSize === 'custom') return; // TODO: suport custom

  return <GameView sgf={sgf} />;
};

export default CreatePage;
