// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


function reducer(state, action) {
  return {
          tool: action[0]
        };
}

export {
  reducer ,
  
}
/* No side effect */
