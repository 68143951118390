// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "../../../node_modules/bs-platform/lib/es6/caml_option.js";
import * as Js_mapperRt from "../../../node_modules/bs-platform/lib/es6/js_mapperRt.js";
import * as SpeedDialTsx from "../../components/SpeedDial.tsx";

var make = SpeedDialTsx.default;

var BaseSpeedDial = {
  make: make
};

var jsMapperConstantArray = [
  /* tuple */[
    -57574468,
    "RIGHT"
  ],
  /* tuple */[
    19067,
    "UP"
  ],
  /* tuple */[
    759637122,
    "DOWN"
  ],
  /* tuple */[
    847852583,
    "LEFT"
  ]
];

function directionToJs(param) {
  return Js_mapperRt.binarySearch(4, param, jsMapperConstantArray);
}

function directionFromJs(param) {
  return Js_mapperRt.revSearch(4, jsMapperConstantArray, param);
}

function SpeedDial(Props) {
  var direction = Props.direction;
  var children = Props.children;
  var flowDirection = Props.flowDirection;
  var icon = Props.icon;
  var directionStr = direction !== undefined ? directionToJs(direction) : undefined;
  var tmp = {
    direction: directionStr,
    children: children
  };
  if (icon !== undefined) {
    tmp.icon = Caml_option.valFromOption(icon);
  }
  if (flowDirection !== undefined) {
    tmp.flowDirection = Caml_option.valFromOption(flowDirection);
  }
  return React.createElement(make, tmp);
}

var make$1 = SpeedDial;

export {
  BaseSpeedDial ,
  directionToJs ,
  directionFromJs ,
  make$1 as make,
  
}
/* make Not a pure module */
